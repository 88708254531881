import {Link as GatsbyLink} from 'gatsby'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import BlogPostPreview from './blog-post-preview'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  title: {
    fontSize: '1.3rem',
    margin: '.5rem 0'
  }
}));

function BlogPostPreviewGrid (props) {
  const classes = useStyles();

  return (

    <div className={classes.root}>
      {props.title && <Typography className={classes.title}>{props.title}</Typography>}
      
      <Grid container spacing={3}>
        {props.nodes &&
          props.nodes.map(node => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={node.id}>
              <BlogPostPreview {...node} />
            </Grid>
          ))}
      </Grid>
      {props.browseMoreHref && (
        <div>
          <Link component={GatsbyLink} to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostPreviewGrid
