import {format} from 'date-fns'
import {Link as GatsbyLink} from 'gatsby'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Zoom from '@material-ui/core/Zoom'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import {buildImageObj, getBlogUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

const useStyles = makeStyles({
  root: {
    height: '100%',
    maxWidth: 960,
    marginBottom: '1rem'
  },
  content: {
    minHeight: 155
  },
  actions: {

  }
});

function BlogPostPreview (props) {
  const classes = useStyles();
  return (
    <Zoom in>
    <div className={classes.root}>
   
    <Card  component={GatsbyLink}
    to={getBlogUrl(props.publishedAt, props.slug.current)}>
    <CardActionArea>
      <CardMedia
        component="img"
        alt={props.mainImage.alt}
        height="180"
        image={imageUrlFor(buildImageObj(props.mainImage))
          .width(600)
          .height(Math.floor((9 / 16) * 600))
          .auto('format')
          .url()}
        title={props.title}
      />
      <CardContent className={classes.content}>
      <Typography variant='caption'>{format(props.publishedAt, 'MMMM Do, YYYY')}</Typography>
        <Typography gutterBottom variant="h6" component="h4">
        {props.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
        {props._rawExcerpt && (
            <PortableText blocks={props._rawExcerpt} />
        )}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  </div> 
  </Zoom>
  )
}

export default BlogPostPreview
